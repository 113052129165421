<template>
  <standard-page title="Available Process Definitions" :definition="$DEFINITIONS.provenance.availableProcessDefinitions">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>Available Processes</span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :row-click="view" :server-side="true" @ready="onReady" list-key="process_definitions" table-id="available_process" total-key="total">
      </data-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AvailableProcessDefinitions",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      api: `${this.$provenance.defaults.baseURL}/provenance/registries/available_definitions`,
      columns: [
        { type: 'name' },
        { type: 'created' },
        { type: 'extrinsic' }
      ],
      table: null,
    };
  },
  methods: {
    onReady(table) {
      this.table = table;
      $('#main-table tbody').on('click', '.newprocess', function () {
        let entity = table.row($(this).parents('tr')).data();
        self.newProcess(entity);
      });
    },
    view(row) {
      this.$router.push({ name: 'available-process', params: { registryid: row.registry, processdefinitionid: row.process_definition } });
    },

  }
}
</script>

<style scoped>
</style>